.apartments {
  &__header {
    display: flex;
    flex-direction: row;

    &--add {
      margin-top: 10px;
      margin-left: 100px;
      text-transform: uppercase;
      background-color: $darkgrey;
      border: 1px solid $darkgrey;
      border-radius: 5px;

      span {
        color: $white;
        text-transform: uppercase;
        margin: 10px 15px 10px 15px;
        display: table;
      }
    }

    .filters {
      display: flex;
      flex-direction: row;
      margin-top: 10px;

      &__header {
        color: $black;
        text-transform: uppercase;
        padding: 1.2rem 1rem 0.5rem 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: 3px solid transparent;
      }

      &__link {
        color: $black;
        text-transform: uppercase;
        padding: 1.2rem 1rem 0.5rem 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transition: transform 0.2s;
        border-bottom: 3px solid transparent;

        &:hover,
        &--active {
          border-bottom-color: $darkgrey;
          font-weight: bold;
          // background: none;
        }
      }
    }
  }

  &__list {
    margin-top: 20px;

    .headers {
      &__link {
        color: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__up {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid $white;
        }
        &__down {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid $white;
        }
      }
    }

    &__details--show:hover {
      display: block;
    }

    &__buttons_column {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .button {
        display: table-cell;
        width: auto;
        margin: 0 5px 0 5px;
        text-align: center;
        vertical-align: middle;
        min-height: 37px;
      }

      svg {
        padding: 5px 0 5px 0;
        color: black;
      }
    }
  }
}

tr.apartments {
  &__list__row_clickable {
    transition: 0.2s ease;

    &:hover {
      background: #eaeaea;
    }
  }
}
