.nav {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  background: $white;
  &__section {
    display: flex;
    &--user {
      display: flex;
      margin-left: auto;
      background-color: $white;
      &__item {
        background-color: $white;
      }
      &__link {
        background: $white;
        color: $black;
        border-right: 1px solid rgba(255,255,255,0.1);
        text-transform: uppercase;
        padding: 1.2rem 2rem 1rem 2rem;
        display: block;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transition: transform 0.2s;
        border-bottom: 5px solid transparent;

        svg {
          height: 20px;
          width: 20px;
          margin-right: 5px;
        }
      }
    }

    &--pages {
      display: flex;
      background-color: $grey;
    }

  }
  &__item {
    display: flex;
    background-color: $grey;
  }
  &__link {
    background: $grey;
    color: $black;
    border-right: 1px solid rgba(255,255,255,0.1);
    text-transform: uppercase;
    padding: 1.2rem 2rem 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;
    border-bottom: 5px solid transparent;

    &:hover, &--active {
      border-bottom-color: $darkgrey;
      font-weight: bold;
      // background: none;
    }
    &--logo {
      &:hover {
        svg {
          transform: none;
        }
      }
      svg {
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }
}


@media all and (max-width: 1200px) {
  .nav {
    &__link {
      padding-left: 1rem;
      padding-right: 1rem;
      svg {
        width: 30px;
      }
      &--logo {
        svg {
          width: 50px;
        }
      }
    }
  }
}

@media all and (max-width: 900px) {
  .nav {
    flex-wrap:wrap;
    &__section {
      order: 1;
    }
  }
}

@media all and (max-width: 650px) {
  .nav {
    &__section {
      width: 100%;
      &--user {
        margin-left: auto;
        order: -1;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        svg {
          display: none;
        }
      }
    }
    &__item {
      width: 33.333333%;
    }
    &__link {
      width: 100%;
      svg {
        // width: 5px;
        max-width: 100%;
      }
      &--logo {
        svg {
          width:100%;
        }
      }
    }
  }
}
