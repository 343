.fa {
  font-size: 18pt !important;
  padding: 2px 5px 2px 5px;
}

.hide {
  text-indent: -999999px;
  font-size: 0;
}

.right {
  display: block;
  margin-left: auto;
}

.button {
  padding: 10px 25px;
  border-radius: 4px;
  background: #e6e6e6;
  color: black;
  font-family: "Roboto",sans-serif;
  transition: none;
  border: 0 none;
  display: block;
  text-decoration: none;
  white-space: inherit;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 13px;
  box-shadow: none;
  letter-spacing: .15em;
  text-align: center;
  cursor: pointer;

  svg {
    display: block;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  span {
    color: black;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
  }
}

.card {
  background: white;
  padding: 5rem;
  box-shadow: 0 1px 10px rgba(0,0,0,0.15);
}

pre.error {
  padding: 5rem;
  background: white;
  line-height: 3;
  white-space: pre-line;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: $white;
  color: $darkgrey;
  text-align: center;
  padding: 5px 0;
  border-radius: 5px;
  border: 1px solid black;
  top: 100%;
  left: 50%;
  margin-left: -60px;

  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-bottom-color: black;
}
