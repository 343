@import url('https://fonts.googleapis.com/css?family=Roboto');

@font-face {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
}

html {
  font-size:10px;
}

body {
  font-size:1.5rem;
  color:$black;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 5rem;
  font-weight: 600;
  letter-spacing:-1px;
  text-rendering: optimizeLegibility;
}

a {
  text-decoration: none;
  color:$black;
}

p a {
  border-bottom: 2px solid $darkgrey;
}

p {
  line-height: 2;
  font-size: 1.6rem;
}

.title {
  position: relative;
  z-index: 2;
  margin:0;
  font-size: 20px;
  line-height:1.1;
  &--long {
    font-size: 20px;
  }
  line-height:1.1;
  // inline link inside
  a {
    border-bottom: 0;
    $darkgreya: rgba($darkgrey,0.8);
    background-image: linear-gradient(to right, $darkgreya 100%, $darkgreya 50%);
  }
}

.title--single {
  max-width: 600px;
  margin-top: -9rem;
  font-size: 6rem;
  text-align: center;
}
