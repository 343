input, textarea {
  width:100%;
  padding:10px;
  border: 1px solid $grey;
  &.input {
    &--error {
      border-color: red;
    }
  }
}

label {
  padding: 10px 0;
  display: block;
}

.form {
  background: white;
  padding: 2rem;
  box-shadow: $grad;
  & + .form {
    margin-top: 4rem;
  }
  // I know this isn't bem but meh
  h2 {
    font-size: 2rem;
    padding-bottom: 2rem;
    margin: 0 0 2rem;
    border-bottom: 1px solid $grey;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .button {
    margin-left: 20px;
  }
}

.availibility {
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 0;

  &__inputs {
    display: flex;
    flex-direction: row;

    select {
      margin-right: 50px;
      width: 25%;
    }

    &__date {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 25%;
      padding: 0;

      span {
        margin-right: 10px;
      }

      input {
        padding: 0;
      }
    }
  }
}
