.single {
  &__hero {
    height: 300px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      display: block;
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }
  &__image {
    width: 50%;
    height: auto;
    object-fit: cover;
    position: absolute;
  }


}
