.confirm {
  ul {
    display: flex;
    flex-direction: column;
    list-style-type: disc;
    padding-left: 0;
    align-items: flex-start;

    li {
      display: flex;
      flex-direction: row;
      list-style: none;
      padding: 5px 0 5px 0;

      label {
        cursor: pointer;
        padding: 0;
        margin-left: 25px;
      }

      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        position: absolute;
      }

    }
  }
}
