.table {
  width: 100%;
  td {
    padding: 5px;
  }
  tr:nth-child(even) {
    background: white;
  }
  tr:nth-child(odd):not(:first-child) {
    background: #f7f7f7;
  }
  tr:nth-child(odd):not(:first-child):hover {
    background: #eaeaea;
  }
  thead tr {
    background: $darkgrey;
    color: white;
  }
}
