.queue {
  &__list {
    margin-top: 10px;

    .button {
      border: 0;
      background: #e6e6e6;
      color: black;
      font-weight: 600;
      cursor: pointer;
      border-radius: 5px;
      text-align: center;
      display: flex;
      flex-direction: row;
      margin: 0 5px 0 5px;
      width: 50%;
      font-size: 8pt;
    }
  }

}