.row--hide {
  display: none;
}

label {
  font-weight: bold;
}

.residents {
  &__list {
    margin-top: 10px;

    table {
      td {
        vertical-align: top;
        width: 50%;
      }
    }
  }
}

.columns {
  display: flex;
  width: 100%;

  div {
    vertical-align: top;
    flex: 1;

    .button {
      width: 50%;
    }

  }
}