#apartment-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#apartment-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .container {
    width: 65%;

    p {
      font-weight: bold;
      font-style: italic;
      font-size: 90%;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      a {
        margin: 0 10px 0 10px;
      }
    }
  }
}

.apartment-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

.images {
  img {
    padding: 10px;
  }
}
