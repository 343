html {
  font-size: 12pt;
}

body {
  background: $purple;
  background: $white;
}

.inner {
  max-width:80%;
  margin: 20px auto 20px auto;
  @media all and (max-width: 900px) {
    padding: 2rem;
  }
}

hr {
  border-top: 2px solid #e6e6e6;
}

.hideondesktop {
  display: none;
}

.hideonmobile {
  display: block;
}

.account_created {
  text-align: center;
  margin-top: 3%;
}
