/*Mobile*/
@media only screen and (max-width: 720px) {
  .hideondesktop {
    display: block;
  }

  .hideonmobile {
    display: none;
  }

  header img {
    max-width: 200px;
  }

  .nav__section a {
    font-size: 88%;
    border-width: 2px;
  }

  .nav__section {
    padding: 0px;
    margin-top: 3px;
    position: relative;
  }

  .nav__section .nav__link {
  }

  .nav__section li {
    width: initial;
    height: 45px;
  }

  .nav__link--active {
    border-width: 2px;
  }

  .inner {
    padding: 0;
    max-width: 90%;
    //margin-bottom: -20px;
  }

  .inner h2 {
    font-size: 135%;
    margin: 0 0 10px 0;
  }

  .confirm ul li input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    position: absolute;
  }

  .inner input.button {
    margin-top: 10px;
  }

  .form {
    padding: 1rem;
  }

  .form label {
    font-size: 1.4rem;
    padding: 20px 0 0 0;
  }

  .apartments__header {
    margin-top: 0px;
  }

  .apartments__list table thead {
    display: none;
  }

  .apartments__list table {
    max-width: 100vw;
  }

  .apartments__list table tr {
    display: flex;
    flex-direction: column;
  }

  .single__hero {
    height: 150px;
  }

  .single__hero img {
    width: 100vw;
    position: absolute;
    left: 0;
    object-fit: cover;
  }

  .single__hero a {
    padding: 0 10px;
    padding: 5px 30px;
    position: relative;
    top: 80px;
    font-size: 52%;
    background-image: rgba(128, 128, 128, 0.8);
    color: #000000;
    text-shadow: 1px 1px 1px rgb(214, 214, 214);
  }

  #apartment-links .container {
    width: 100%;
  }

  #apartment-links .container .buttons {
    flex-direction: column;
  }

  #apartment-links .buttons .button {
    width: 80vw;
    margin-bottom: 10px !important;
  }

  .apartments__list table tr {
    border-top: 1px solid #cecccc;
    padding: 5px;
  }

  .apartments__list table tr:not(:first-child) {
    margin-top: 5px;
  }

  .apartments__list table td {
    padding: 5p;
  }

  .apartments__list__buttons_column {
    justify-content: flex-end;
  }

  .apartments__header {
    height: 30px;
    margin: 40px 0;
  }

  .apartments__header .filters {
    margin-top: 20px;
    font-size: 11px;
    position: relative;
    left: -10px;
  }

  .apartments__header .filters__header {
    position: absolute;
    font-weight: bold;
    top: -24px;
    left: -15px;
  }

  .apartments__header .filters__link {
    padding: 1.2rem 0.2rem 0.5rem 0.2rem;
  }

  .apartments__header .button.apartments__header--add {
    position: absolute;
    top: -55px;
    padding: 0 6px;
    font-size: 90%;
    right: 9px;
  }

  #apartment-info label {
    margin-bottom: -5px;
  }

  #apartment-info div {
    padding-left: 5px;
    margin-bottom: 10px;
  }
}
